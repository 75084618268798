import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { SlackBlocksModule } from 'src/app/components/slack-blocks/slack-blocks.module';
import { SharedDirectivesModule } from 'src/directives/shared-directives.module';

import { SurveyAnalyticsPage } from '../survey-analytics/survey-analytics.page';

import { MessageAnalyticsPage } from './message-analytics/message-analytics.page';
import { MessagingComponent } from './messaging.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    SharedDirectivesModule,
    NgxDatatableModule,
    SlackBlocksModule,
    NgxTippyModule,
    SurveyAnalyticsPage,
  ],
  declarations: [MessagingComponent, MessageAnalyticsPage],
  exports: [MessagingComponent, MessageAnalyticsPage],
})
export class MessagingModule {}
